<template>
  <Layout>
    <template v-if="this.$route.name == 'add-promotion'">
      <PageHeader :title="title1" :items="items"></PageHeader>
    </template>
    <template v-else>
      <PageHeader :title="title2" :items="items"></PageHeader>
    </template>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" id="medinext">
            <b-form enctype="multipart/form-data" ref="promotion">
              <div class="row">
                <b-form-group class="col-12">
                  <label for="pl"
                    >Choose Platform <span style="color: red">*</span></label
                  >
                  <b-form-radio-group
                    id="pl"
                    :options="platformType"
                    name="radio-options"
                    :class="{
                      'is-invalid': submitted && $v.form.platform.$invalid,
                    }"
                    v-model="form.platform"
                  >
                  </b-form-radio-group>
                  <div
                    v-if="submitted && !$v.form.platform.required"
                    class="invalid-feedback"
                  >
                    Platform is required.
                  </div>
                </b-form-group>
                <b-form-group class="col-lg-4 col-md-6 col-sm-12">
                  <label for="total_reach"
                    >Target Reach <span style="color: red">*</span></label
                  >
                  <b-form-input
                    type="number"
                    min="1"
                    id="total_reach"
                    placeholder="Enter Target Reach"
                    v-model="form.total_reach"
                    :class="{
                      'is-invalid': submitted && $v.form.total_reach.$invalid,
                    }"
                  ></b-form-input>
                  <div
                    v-if="submitted && !$v.form.total_reach.required"
                    class="invalid-feedback"
                  >
                    Target Reach is required.
                  </div>
                </b-form-group>
                <b-form-group class="col-lg-4 col-md-6 col-sm-12">
                  <label for="target_reach_dashboard"
                    >Target Reach for Dashbaord
                    <span style="color: red">*</span></label
                  >
                  <b-form-input
                    type="number"
                    min="1"
                    id="target_reach_dashboard"
                    placeholder="Enter Target Reach for Dashbaord"
                    v-model="form.target_reach_dashboard"
                    :class="{
                      'is-invalid':
                        submitted && $v.form.target_reach_dashboard.$invalid,
                    }"
                  ></b-form-input>
                  <div
                    v-if="submitted && !$v.form.target_reach_dashboard.required"
                    class="invalid-feedback"
                  >
                    Target Reach for Dashbaord is required.
                  </div>
                </b-form-group>
                <b-form-group class="col-lg-4 col-md-6 col-sm-12">
                  <label for="total_views"
                    >Views <span style="color: red">*</span></label
                  >
                  <b-form-input
                    type="number"
                    min="1"
                    id="total_views"
                    placeholder="Enter Views"
                    v-model="form.total_views"
                    :class="{
                      'is-invalid': submitted && $v.form.total_views.$invalid,
                    }"
                  ></b-form-input>
                  <div
                    v-if="submitted && !$v.form.total_views.required"
                    class="invalid-feedback"
                  >
                    Views is required.
                  </div>
                </b-form-group>
                <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                  <label for="session-date-time"
                    >Start DateTime <span style="color: red"> *</span></label
                  >
                  <date-picker
                    type="datetime"
                    v-model="start_datetime"
                    placeholder="yyyy-mm-dd hh:mm:ss"
                    :confirm="true"
                    :disabled-date="disabledDate"
                  ></date-picker>
                </b-form-group>
                <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                  <label for="session-end-time"
                    >End DateTime <span style="color: red"> *</span></label
                  >
                  <date-picker
                    type="datetime"
                    v-model="end_datetime"
                    placeholder="yyyy-mm-dd hh:mm:ss"
                    :confirm="true"
                    :disabled-date="disableStartDate"
                  ></date-picker>
                </b-form-group>
                <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                  <label for="logo">Logo</label>
                  <b-form-file
                    accept="image/*"
                    placeholder="Choose a file or drop it here..."
                    id="logo"
                    @change="readFile($event, 'logo_img')"
                    ref="logo_img"
                  >
                  </b-form-file>
                  <template
                    v-if="$route.name == 'edit-promotion' && edit.logo_img_url"
                  >
                    <img
                      :src="edit.logo_img_url"
                      width="128px"
                      height="128px"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                  <template v-if="logo_img_url">
                    <img
                      :src="logo_img_url"
                      width="128px"
                      height="128px"
                      ref="logo_img_url"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                </b-form-group>
                <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                  <label for="sponser_name">Sponsor Name</label>
                  <b-form-input
                    type="text"
                    id="sponser_name"
                    placeholder="Enter Sponser Name"
                    v-model="form.sponsor_name"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="row">
                <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                  <label for="form.content_type"
                    >Content Type <span style="color: red">*</span></label
                  >
                  <b-form-select
                    id="content_type"
                    :options="content_type"
                    v-model="form.content_type"
                    value-field="value"
                    text-field="text"
                    @change="changeContentType"
                    :class="{
                      'is-invalid': submitted && $v.form.content_type.$invalid,
                    }"
                  >
                  </b-form-select>
                  <div
                    v-if="submitted && !$v.form.content_type.required"
                    class="invalid-feedback"
                  >
                    Content Type is required.
                  </div>
                </b-form-group>
                <template
                  v-if="
                    form.content_type != 'poster' &&
                    form.content_type != '' &&
                    form.content_type != 'small_card' &&
                    form.content_type != 'pop_up'
                  "
                >
                  <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                    <label for="content_id"
                      >Content Title <span style="color: red">*</span></label
                    >
                    <template
                      v-if="
                        form.content_type == 'case' ||
                        form.content_type == 'newsletter' ||
                        form.content_type == 'video' ||
                        form.content_type == 'post'
                      "
                    >
                      <multiselect
                        v-model="form.content_id"
                        :options="getContentType"
                        :multiple="false"
                        track-by="id"
                        label="title"
                        @search-change="fetchAttachment"
                        :class="{
                          'is-invalid':
                            submitted && $v.form.content_id.$invalid,
                        }"
                        placeholder="Type here to search"
                      >
                        <span slot="noOptions">Type here to search</span>
                      </multiselect>
                      <div
                        v-if="submitted && !$v.form.content_id.required"
                        class="invalid-feedback"
                      >
                        Content Title is required.
                      </div>
                    </template>
                    <template v-else-if="form.content_type == 'journal'">
                      <multiselect
                        v-model="form.content_id"
                        :options="getContentType"
                        :multiple="false"
                        track-by="id"
                        label="header"
                        @search-change="fetchArticleBySearch"
                        :class="{
                          'is-invalid':
                            submitted && $v.form.content_id.$invalid,
                        }"
                        placeholder="Type here to search"
                      >
                        <span slot="noOptions">Type here to search</span>
                      </multiselect>
                      <div
                        v-if="submitted && !$v.form.content_id.required"
                        class="invalid-feedback"
                      >
                        Content Title is required.
                      </div>
                    </template>
                    <template v-else-if="form.content_type == 'live_event'">
                      <multiselect
                        v-model="form.content_id"
                        :options="getContentType"
                        :multiple="false"
                        track-by="id"
                        label="title"
                        @search-change="fetchLiveEventBySearch"
                        :class="{
                          'is-invalid':
                            submitted && $v.form.content_id.$invalid,
                        }"
                        placeholder="Type here to search"
                      >
                        <span slot="noOptions">Type here to search</span>
                      </multiselect>
                      <div
                        v-if="submitted && !$v.form.content_id.required"
                        class="invalid-feedback"
                      >
                        Content Title is required.
                      </div>
                    </template>
                  </b-form-group>
                </template>
              </div>
              <div class="row">
                <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                  <label for="data_filter_id"
                    >Data Filter <span style="color: red">*</span></label
                  >
                  <b-form-select
                    id="data_filter_id"
                    :options="getDataFilter"
                    value-field="id"
                    text-field="name"
                    v-model="form.data_filter_id"
                    :class="{
                      'is-invalid':
                        submitted && $v.form.data_filter_id.$invalid,
                    }"
                    :disabled="$route.name == 'edit-promotion' ? true : false"
                  >
                  </b-form-select>
                  <div
                    v-if="submitted && !$v.form.data_filter_id.required"
                    class="invalid-feedback"
                  >
                    Data Filter is required.
                  </div>
                </b-form-group>
                <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                  <label for="sponser_name">Ad Title</label>
                  <b-form-input
                    type="text"
                    id="sponser_name"
                    placeholder="Enter Ad Title"
                    v-model="form.ad_title"
                  ></b-form-input>
                </b-form-group>
                <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                  <label for="ad_placement"
                    >Ad Placement <span style="color: red">*</span></label
                  >
                  <multiselect
                    :options="options"
                    :multiple="true"
                    track-by="value"
                    label="text"
                    :class="{
                      'is-invalid': submitted && $v.form.ad_placement.$invalid,
                    }"
                    placeholder="Type here to search"
                    v-model="form.ad_placement"
                  >
                    <span slot="noOptions">Type here to search</span>
                  </multiselect>
                  <div
                    v-if="submitted && !$v.form.ad_placement.required"
                    class="invalid-feedback"
                  >
                    Ad Placement is required.
                  </div>
                </b-form-group>
                <b-form-group class="col-lg-3 col-md-3 col-sm-12">
                  <label for="total_views">Ad Position</label>
                  <b-form-input
                    type="number"
                    min="1"
                    id="total_views"
                    placeholder="Enter Ad Position"
                    v-model="form.ad_position"
                    :disabled="form.content_type === 'pop_up'"
                    :class="{
                      'is-invalid': submitted && $v.form.ad_position.$invalid,
                    }"
                  ></b-form-input>
                  <div
                    v-if="submitted && !$v.form.ad_position.required"
                    class="invalid-feedback"
                  >
                    Ad Position is required when Content type is Poster.
                  </div>
                </b-form-group>
                <b-form-group
                  class="col-lg-3 col-md-3 col-sm-12"
                  label="CTR (%)"
                  label-for="input-ctr"
                >
                  <b-form-input
                    id="input-ctr"
                    type="number"
                    min="0"
                    placeholder="Enter CTR in percentage (%)"
                    v-model="form.ctr"
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-lg-6 col-md-6 col-sm-12"
                  v-if="
                    form.ad_placement.length > 0 &&
                    form.ad_placement.some((item) => item.value === 'feed')
                  "
                >
                  <label for="ad_creative"
                    >Feed Ad Creative
                    <span style="color: red"
                      >[Required image size : 1080px x 2340px] *</span
                    ></label
                  >
                  <b-form-file
                    accept="image/png, image/jpeg, image/jpg"
                    placeholder="Choose a file or drop it here..."
                    id="feed_creative"
                    ref="feed_creative"
                    @change="readFile($event, 'feed_creative')"
                  >
                  </b-form-file>
                  <template
                    v-if="
                      $route.name == 'edit-promotion' && edit.feed_creative_url
                    "
                  >
                    <img
                      :src="edit.feed_creative_url"
                      width="128px"
                      height="128px"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                  <template v-if="feed_creative_url">
                    <img
                      :src="feed_creative_url"
                      width="128px"
                      height="128px"
                      ref="feed_creative_url"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                </b-form-group>
                <b-form-group
                  class="col-lg-6 col-md-6 col-sm-12"
                  v-if="
                    form.ad_placement.length > 0 &&
                    form.ad_placement.some((item) => item.value === 'latest_video')
                  "
                >
                  <label for="ad_creative"
                    >Latest Video Ad Creative
                    <span style="color: red"
                      >[The image width should be a maximum of 312px.] *</span
                    ></label
                  >
                  <b-form-file
                    accept="image/png, image/jpeg, image/jpg"
                    placeholder="Choose a file or drop it here..."
                    id="latest_video_creative"
                    ref="latest_video_creative"
                    @change="readFile($event, 'latest_video_creative')"
                  >
                  </b-form-file>
                  <template
                    v-if="
                      $route.name == 'edit-promotion' && edit.latest_video_url
                    "
                  >
                    <img
                      :src="edit.latest_video_url"
                      width="128px"
                      height="128px"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                  <template v-if="latest_video_url">
                    <img
                      :src="latest_video_url"
                      width="128px"
                      height="128px"
                      ref="feed_creative_url"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                </b-form-group>
                <b-form-group
                  class="col-lg-6 col-md-6 col-sm-12"
                  v-if="
                    form.ad_placement.length > 0 &&
                    form.ad_placement.some((item) => item.value === 'trending_video')
                  "
                >
                  <label for="ad_creative"
                    >Trending Video Ad Creative
                    <span style="color: red"
                      >[The image width should be a maximum of 312px.] *</span
                    ></label
                  >
                  <b-form-file
                    accept="image/png, image/jpeg, image/jpg"
                    placeholder="Choose a file or drop it here..."
                    id="trending_video_creative"
                    ref="trending_video_creative"
                    @change="readFile($event, 'trending_video_creative')"
                  >
                  </b-form-file>
                  <template
                    v-if="
                      $route.name == 'edit-promotion' && edit.trending_video_url
                    "
                  >
                    <img
                      :src="edit.trending_video_url"
                      width="128px"
                      height="128px"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                  <template v-if="trending_video_url">
                    <img
                      :src="trending_video_url"
                      width="128px"
                      height="128px"
                      ref="feed_creative_url"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                </b-form-group>
                <b-form-group
                  class="col-lg-6 col-md-6 col-sm-12"
                  v-if="
                    form.ad_placement.length > 0 &&
                    form.ad_placement.some((item) => item.value === 'case_discussion')
                  "
                >
                  <label for="ad_creative"
                    >Case Discussion Ad Creative
                    <span style="color: red"
                      >[The image width should be a maximum of 312px.] *</span
                    ></label
                  >
                  <b-form-file
                    accept="image/png, image/jpeg, image/jpg"
                    placeholder="Choose a file or drop it here..."
                    id="case_discussion_creative"
                    ref="case_discussion_creative"
                    @change="readFile($event, 'case_discussion_creative')"
                  >
                  </b-form-file>
                  <template
                    v-if="
                      $route.name == 'edit-promotion' && edit.case_discussion_url
                    "
                  >
                    <img
                      :src="edit.case_discussion_url"
                      width="128px"
                      height="128px"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                  <template v-if="case_discussion_url">
                    <img
                      :src="case_discussion_url"
                      width="128px"
                      height="128px"
                      ref="case_discussion_url"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                </b-form-group>
                <b-form-group
                  class="col-lg-6 col-md-6 col-sm-12"
                  v-if="
                    form.ad_placement.length > 0 &&
                    form.ad_placement.some((item) => item.value === 'journal')
                  "
                >
                  <label for="journal_creative"
                    >Journal Ad Creative
                    <span style="color: red"
                      >[Required image size : 1080px x 2340px] *</span
                    ></label
                  >
                  <b-form-file
                    accept="image/png, image/jpeg, image/jpg"
                    placeholder="Choose a file or drop it here..."
                    id="journal_creative"
                    ref="journal_creative"
                    @change="readFile($event, 'journal_creative')"
                  >
                  </b-form-file>
                  <template
                    v-if="
                      $route.name == 'edit-promotion' &&
                      edit.journal_creative_url
                    "
                  >
                    <img
                      :src="edit.journal_creative_url"
                      width="128px"
                      height="128px"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                  <template v-if="journal_creative_url">
                    <img
                      :src="journal_creative_url"
                      width="128px"
                      height="128px"
                      ref="journal_creative_url"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                </b-form-group>
                <b-form-group
                  class="col-lg-6 col-md-6 col-sm-12"
                  v-if="
                    form.ad_placement.length > 0 &&
                    form.ad_placement.some((item) =>
                      item.value.includes('_detail')
                    )
                  "
                >
                  <label for="ad_creative"
                    >Detail Ad Creative
                    <span style="color: red"
                      >[Required image size : 800px x 200px] *</span
                    ></label
                  >
                  <b-form-file
                    accept="image/png, image/jpeg, image/jpg"
                    placeholder="Choose a file or drop it here..."
                    id="detail_creative"
                    ref="detail_creative"
                    @change="readFile($event, 'detail_creative')"
                  >
                  </b-form-file>
                  <template
                    v-if="
                      $route.name == 'edit-promotion' &&
                      edit.detail_creative_url
                    "
                  >
                    <img
                      :src="edit.detail_creative_url"
                      width="128px"
                      height="128px"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                  <template v-if="detail_creative_url">
                    <img
                      :src="detail_creative_url"
                      width="128px"
                      height="128px"
                      ref="detail_creative_url"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                </b-form-group>
                <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                  <label for="communities">Community</label>
                  <multiselect
                    :options="getCommunities"
                    :multiple="true"
                    track-by="id"
                    label="title"
                    @search-change="fetchCommunity"
                    placeholder="Type here to search"
                    v-model="form.communities"
                  >
                    <span slot="noOptions">Type here to search</span>
                  </multiselect>
                </b-form-group>
                <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                  <label for="cta_text">CTA Text</label>
                  <b-form-input
                    type="text"
                    id="cta_text"
                    placeholder="Enter CTA Text"
                    v-model="form.cta_text"
                  ></b-form-input>
                </b-form-group>
                <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                  <label for="cta_link">CTA Link</label>
                  <b-form-input
                    type="text"
                    id="cta_link"
                    placeholder="Enter CTA Link"
                    :class="{
                      'is-invalid': submitted && $v.form.cta_link.$invalid,
                    }"
                    v-model="form.cta_link"
                  ></b-form-input>
                  <div
                    v-if="submitted && !$v.form.cta_link.required"
                    class="invalid-feedback"
                  >
                    CTA Link is required.
                  </div>
                </b-form-group>
                <b-form-group
                  label-for="color"
                  class="col-lg-6 col-md-6 col-sm-12"
                >
                  <label>CTA Color</label>
                  <b-form-input
                    type="color"
                    id="color"
                    v-model="form.cta_color"
                  />
                </b-form-group>
                <b-form-group class="col-12">
                  <div style="display: flex; gap: 10px; padding: 2px 0">
                    <b-form-checkbox v-model="form.is_active"
                      >Is Active</b-form-checkbox
                    >
                    <b-form-checkbox v-model="form.for_views"
                      >For views only / promotion will not visible on
                      website</b-form-checkbox
                    >
                  </div>
                </b-form-group>
              </div>
              <b-button
                type="submit"
                variant="primary"
                class="mr-2"
                @click.prevent="submitData"
              >
                <span v-if="this.$route.name == 'add-promotion'"
                  >Save Data</span
                >
                <span v-else>Update Data</span>
              </b-button>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import promotionMixin from "../../../mixins/ModuleJs/promotion";
import { required, requiredIf } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";

export default {
  data() {
    return {
      submitted: false,
      title1: "Add Promotion",
      title2: "Edit Promotion",
      items: [
        {
          text: "List",
          href: "/promotion",
        },
        {
          text: "Data",
        },
      ],
    };
  },
  mixins: [MixinRequest, promotionMixin],
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
  },
  validations: {
    form: {
      platform: { required },
      content_type: { required },
      total_reach: { required },
      total_views: { required },
      content_id: {
        required: requiredIf(
          (form) =>
            form.content_type != "poster" &&
            form.content_type != "small_card" &&
            form.content_type != "pop_up"
        ),
      },
      cta_link: {
        required: requiredIf(
          (form) =>
            form.content_type == "poster" ||
            form.content_type == "small_card" ||
            form.content_type == "pop_up"
        ),
      },
      ad_placement: { required },
      data_filter_id: { required },
      target_reach_dashboard: { required },
      ad_position: {
        required: requiredIf((form) => form.content_type == "poster"),
      },
    },
    // selectedStartDate: {
    //    start_date: { required },
    //    start_time: { required },
    // }
  },
};
</script>
